import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Form, Input, Button, PageHeader, message, Upload, Select, Spin } from 'antd';
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import AppContent from '../../../components/Common/Content/Content';
import { CustomModal } from '../../../components/Common/CustomModal';
import { API_URL, JWT_TOKEN } from '../../../constants/config';
import { get_location_list } from '../../../services/locations/queries';
import { ILocations } from '../../../services/locations/types';
import { upload_price_group_mutation } from '../../../services/products/mutations';
import {
  get_price_groups_by_location,
  prices_download_by_pricegroupid
} from '../../../services/products/queries';
import { getLocalStorage } from '../../../utils/storage.utils';
import ReuseChannel from '../../channel/Reuse';
const { Option } = Select;

const PriceGroupsUpload = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isloading, setIsloading] = useState<boolean>(false);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [locationId, setLocationId] = useState<any>('none');
  const [priceGroupId, setPriceGroupId] = useState<any>('none');
  const [priceGroup, setPriceGroup] = useState<any[]>([]);
  const [responsize, setResponsize] = useState(false);
  const { data: locationsList } = useQuery(['locations'], async () => get_location_list());
  const uploadMutation = useMutation(upload_price_group_mutation);

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const beforeUpload = (values: any) => {
    return false;
  };
  const uploadProps: UploadProps = {
    name: 'media',
    multiple: false,
    maxCount: 1,
    fileList: fileList,
    accept: '.xlsx',
    onChange: onChange,
    beforeUpload: beforeUpload,
    showUploadList: {
      showPreviewIcon: false,
      showRemoveIcon: true
    }
  };
  const breadcrumbItems = [
    {
      label: 'Products',
      link: '/products'
    },
    {
      label: 'Price Group',
      link: '/products/price-group'
    }
  ];

  const handleUpload = async () => {
    setIsloading(true);
    if (locationId === 'none' || priceGroupId == 'none') {
      message.error('Please select both location and price group!');
      setIsloading(false);
      return;
    }
    try {
      const formData = new FormData();
      formData.append('file', fileList[0].originFileObj as RcFile);
      const values = {
        id: priceGroupId,
        file: formData
      };
      uploadMutation.mutateAsync(values, {
        onSuccess: async (data: any) => {
          message.success('Uploaded successfully');
          setIsloading(false);
          // navigate(`/price-groups/${values.id}`);
          // console.log(data);
        },
        onError: (error: any) => {
          displayError(error);
          // console.log(error);
          setIsloading(false);
          // message.error(error.message);
        }
      });
    } catch (error: any) {
      message.error('unable to upload');
      setIsloading(false);
    }
  };
  const displayError = (error: AxiosError) => {
    const listOfErrors: string[] = [];
    if (error.response?.data) {
      (error.response.data as any[]).forEach((item: string[][]) => {
        if (item) {
          console.log(item);
          item.map((i) => {
            listOfErrors.push(i[0]);
          });
        }
      });
    } else {
      listOfErrors.push(error.message);
    }
    setErrors(listOfErrors);
    setIsErrorOpen(true);
  };

  const onLocationsChange = async (val: any) => {
    try {
      setLocationId(val);
      const response = await get_price_groups_by_location(val);
      setPriceGroup(response.data);
      if (response.data.length > 0) {
        setPriceGroupId(response.data[0].id);
      } else {
        setPriceGroupId('none');
      }
    } catch (error: any) {
      message.error('unable to fetch price group');
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 650) {
      // setCollapsed(true);
      setResponsize(true);
    } else {
      setResponsize(false);
    }
    function handleResize() {
      if (window.innerWidth <= 650) {
        // setCollapsed(true);
        setResponsize(true);
      } else {
        setResponsize(false);
      }
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <div>
      <CustomModal isModalOpen={isErrorOpen} setIsModalOpen={setIsErrorOpen} title={'Errors'}>
        <div className="grid grid-cols-1 gap-2 ">
          {errors.map((error, index) => {
            return (
              <div key={index}>
                <div className="text-red-600">{`${index + 1}. ${error}`}</div>
              </div>
            );
          })}
        </div>
      </CustomModal>
      <Spin spinning={isloading}>
        <AppContent breadcrumbItems={breadcrumbItems}>
          <PageHeader
            title="Price Information by Location"
            style={{
              padding: '8px 0px'
            }}
          />
          <div
            className={
              responsize ? 'grid grid-cols-2 gap-5 mt-10' : 'grid grid-cols-3 gap-5 mt-10'
            }>
            <Select
              placeholder="Select a location!"
              value={locationId}
              onChange={(val) => onLocationsChange(val)}>
              <Option value="">All</Option>
              {locationsList?.data.results.map((value: ILocations) => {
                return (
                  <Option value={value.id} key={value.id}>
                    {value.name}
                  </Option>
                );
              })}
            </Select>
            <Select
              placeholder="Select a price group!"
              value={priceGroupId}
              onChange={(val) => setPriceGroupId(val)}>
              {priceGroup?.map((value: any) => {
                return (
                  <Option value={value.id} key={value.id}>
                    {value.name}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="grid grid-cols-1 gap-5 mt-10 mb-5">
            <div className="grid grid-cols-2 gap-5 ">
              <Upload {...uploadProps}>
                <Button
                  style={{ width: 150 }}
                  icon={<UploadOutlined />}
                  disabled={fileList.length > 0}>
                  Upload File
                </Button>
              </Upload>
            </div>
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={fileList.length === 0}
              loading={isloading}
              style={{ width: 150 }}>
              {isloading ? 'loading' : 'Start Upload'}
            </Button>
          </div>

          {/* <div className="flex justify-end mt-5">
          <Button
            type="default"
            className="ml-5"
            htmlType="button"
            onClick={() => navigate('/products')}>
            Back
          </Button>
        </div> */}
          <ReuseChannel slug={`price_upload_${null}`} />
        </AppContent>
      </Spin>
    </div>
  );
};

export default PriceGroupsUpload;
